(function($){
	$('.actu .twitter-link').on('click', function(e){
		var product_url = encodeURIComponent(location.href),
			product_name = encodeURIComponent($('h1').text());
		window.open('https://twitter.com/intent/tweet?text=' + product_name + ' ' + product_url, 'sharertwt', 'toolbar=0,status=0,width=640,height=445');
		e.preventDefault();
	});

	$('.actu .facebook-link').on('click', function(e){
		window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(location.href), 'sharer', 'toolbar=0,status=0,width=660,height=445');
		e.preventDefault();
	});

	$('.actu .linkedin-link').on('click', function(e){
		window.open('https://www.linkedin.com/shareArticle?mini=true&amp;url=' + encodeURIComponent(location.href), 'sharerin', 'toolbar=0,status=0,width=767,height=720');
		e.preventDefault();
	});

})(jQuery);
