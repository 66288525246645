jQuery(document).ready(function() {

  var fnSameHeights = function(elems) {
    elems.css('height', 'auto');
    elems.css('height', Math.max.apply(Math, elems.map(function() {
      return $(this).outerHeight();
    }).get() ));
  }

  var $mosaicContent = $('.sameheights');
  var $statContent = $('.stat-height');
  var $matContent = $('.mat-height>.vc_column-inner>.wpb_wrapper');


  fnSameHeights($mosaicContent);
  fnSameHeights($statContent);
  fnSameHeights($matContent);

  window.resizedFinished = setTimeout(function(){
    fnSameHeights($mosaicContent);
    fnSameHeights($statContent);
    fnSameHeights($matContent);
  }, 350);

  $(window).resize(function() {
      clearTimeout(window.resizedFinished);
      window.resizedFinished = setTimeout(function(){
        fnSameHeights($mosaicContent);
        fnSameHeights($statContent);
        fnSameHeights($matContent);
      }, 250);
  });

});
