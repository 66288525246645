jQuery(function($) {
    // Asynchronously Load the map API
    var script = document.createElement('script');
    var key = "AIzaSyDFjHLbzKIDu4lhXza3R9oEPsH00tYRzwI";
    script.src = "https://maps.googleapis.com/maps/api/js?sensor=false&key=AIzaSyDFjHLbzKIDu4lhXza3R9oEPsH00tYRzwI&callback=initialize";
    document.body.appendChild(script);
});



function initialize() {

    var map;
    var icon = document.getElementById("map_canvas").getAttribute('data-marker');
    var bounds = new google.maps.LatLngBounds();
    var map_styles =[
			    {
			        "featureType": "water",
			        "elementType": "geometry",
			        "stylers": [
			            {
			                "color": "#e9e9e9"
			            },
			            {
			                "lightness": 17
			            }
			        ]
			    },
			    {
			        "featureType": "landscape",
			        "elementType": "geometry",
			        "stylers": [
			            {
			                "color": "#f5f5f5"
			            },
			            {
			                "lightness": 20
			            }
			        ]
			    },
			    {		"featureType": "road.highway",
			        "elementType": "geometry.fill",
			        "stylers": [
			            {
			                "color": "#ffffff"
			            },
			            {
			                "lightness": 17
			            }
			        ]
			    },
			    {
			        "featureType": "road.highway",
			        "elementType": "geometry.stroke",
			        "stylers": [
			            {
			                "color": "#ffffff"
			            },
			            {
			                "lightness": 29
			            },
			            {
			                "weight": 0.2
			            }
			        ]
			    },
			    {
			        "featureType": "road.arterial",
			        "elementType": "geometry",
			        "stylers": [
			            {
			                "color": "#ffffff"
			            },
			            {
			                "lightness": 18
			            }
			        ]
			    },
			    {
			        "featureType": "road.local",
			        "elementType": "geometry",
			        "stylers": [
			            {
			                "color": "#ffffff"
			            },
			            {
			                "lightness": 16
			            }
			        ]
			    },
			    {
			        "featureType": "poi",
			        "elementType": "geometry",
			        "stylers": [
			            {
			                "color": "#f5f5f5"
			            },
			            {
			                "lightness": 21
			            }
			        ]
			    },
			    {
			        "featureType": "poi.park",
			        "elementType": "geometry",
			        "stylers": [
			            {
			                "color": "#dedede"
			            },
			            {
			                "lightness": 21
			            }
			        ]
			    },
			    {
			        "elementType": "labels.text.stroke",
			        "stylers": [
			            {
			                "visibility": "on"
			            },
			            {
			                "color": "#ffffff"
			            },
			            {
			                "lightness": 16
			            }
			        ]
			    },
			    {
			        "elementType": "labels.text.fill",
			        "stylers": [
			            {
			                "saturation": 36
			            },
			            {
			                "color": "#333333"
			            },
			            {
			                "lightness": 40
			            }
			        ]
			    },
			    {
			        "elementType": "labels.icon",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "transit",
			        "elementType": "geometry",
			        "stylers": [
			            {
			                "color": "#f2f2f2"
			            },
			            {
			                "lightness": 19
			            }
			        ]
			    },
			    {
			        "featureType": "administrative",
			        "elementType": "geometry.fill",
			        "stylers": [
			            {
			                "color": "#fefefe"
			            },
			            {
			                "lightness": 20
			            }
			        ]
			    },
			    {
			        "featureType": "administrative",
			        "elementType": "geometry.stroke",
			        "stylers": [
			            {
			                "color": "#fefefe"
			            },
			            {
			                "lightness": 17
			            },
			            {
			                "weight": 1.2
			            }
			        ]
			    }
			];

    var mapOptions = {
        mapTypeId: 'roadmap',  //'hybrid','satellite','terrain'
        disableDefaultUI: true,
        scrollwheel: false,
        styles: map_styles
    };


    // Display a map on the page
    map = new google.maps.Map(document.getElementById("map_canvas"), mapOptions);
    map.setTilt(45);


    // Multiple Markers
    var markers = [
        ['HAGUENAU', 48.801637,7.812538],
        ['WOUSTVILLER', 49.069610,7.021652],
        ['FEGERSHEIM', 48.496683,7.688306],
        ['ETUPES', 47.510623,6.858480],
        ['RICHWILLER', 47.789625,7.301256]
    ];

    // Info Window Content
    var infoWindowContent = [
        ['<div class="info_markers">' +
        '<h3>HAGUENAU</h3>' +
        '<p>3a rue Branly – 67500 Haguenau</p>'+
        '<p>Tél : <a href="tel:0033388074210">03 88 07 42 10</a></p>'+
        '<p>Fax : 03 88 07 42 19</p>'+'</div>'],
       ['<div class="info_markers">' +
        '<h3>WOUSTVILLER</h3>' +
        '<p>ZA Rue des Forgerons – 57915 Woustviller</p>'+
        '<p>Tél : <a href="tel:0033387285555">03 88 28 55 55</a></p>' +
        '<p>Fax : 03 87 28 55 59</p>'+'</div>'],
        ['<div class="info_markers">' +
         '<h3>FEGERSHEIM</h3>' +
         '<p>146 Rue de Lyon – 67640 Fegersheim</p>'+
         '<p>Tél : <a href="tel:0033388644010">03 88 64 40 10</a></p>' +
         '<p>Fax : 03 88 64 40 19</p>'+'</div>'],
          ['<div class="info_markers">' +
          '<h3>ETUPES</h3>' +
          '<p>85 Avenue René Jacot – 25460 Etupes</p>'+
          '<p>Tél : <a href="tel:0033381300865">03 81 30 08 65</a></p>' +
          '<p>Fax : 03 81 30 79 78</p>'+'</div>'],
          ['<div class="info_markers">' +
           '<h3>RICHWILLER</h3>' +
           '<p>19 Rue des Artisans – 68120 Richwiller</p>'+
           '<p>Tél : <a href="tel:0033389525000">03 89 52 50 00</a></p>' +
           '<p>Fax : 03 89 52 50 01</p>'+'</div>']
    ];



    // Display multiple markers on a map
    var infoWindow = new google.maps.InfoWindow(), marker, i;

    // Loop through our array of markers & place each one on the map
    for( i = 0; i < markers.length; i++ ) {
        var position = new google.maps.LatLng(markers[i][1], markers[i][2]);
        bounds.extend(position);
        marker = new google.maps.Marker({
            position: position,
            map: map,
            title: markers[i][0],
            icon: {
        			url: icon,
        			scaledSize: new google.maps.Size(24, 42)
        		}
        });


        // Allow each marker to have an info window
        google.maps.event.addListener(marker, 'click', (function(marker, i) {
            return function() {
                infoWindow.setContent(infoWindowContent[i][0]);
                infoWindow.open(map, marker);
            }
        })(marker, i));

        // Automatically center the map fitting all markers on the screen
        map.fitBounds(bounds);
    }

    // Override our map zoom level once our fitBounds function runs (Make sure it only runs once)
    var boundsListener = google.maps.event.addListener((map), 'bounds_changed', function(event) {
        // this.setZoom(9);
        google.maps.event.removeListener(boundsListener);
    });
}
